import React, { Component } from "react";
import { Link } from "react-router-dom";

import PageTemplate from "../Components/PageTemplate";

export default class NotFound extends Component {
  render() {
    return (
      <PageTemplate
        body={
          <div className="container">
            <h5>Maaf, halaman yang Anda cari tidak ditemukan</h5>
            <h5>
              Silahkan klik link <Link to="/">ini</Link> untuk kembali ke
              halaman Home
            </h5>
          </div>
        }
      />
    );
  }
}
