import http from "./httpService";

const apiURL = process.env.REACT_APP_APIURL + "/mading";

export function getMadings() {
  return http.get(apiURL + "/published");
}

export function getSingleMading(id) {
  return http.get(apiURL + "/single/" + id);
}

export function getMadingByKategori(kategoriId) {
  return http.get(apiURL + "/published/kategori/" + kategoriId);
}

export function putTambahView(id, mading) {
  mading.klik += 1;
  return http.put(apiURL + "/klik/" + id, mading);
}
