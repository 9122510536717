import React, { Component } from "react";
import { Link } from "react-router-dom";

import { getCompanyProfile } from "../services/companyProfileService";
import { getWebUtils } from "../services/webUtilsService";
import { getMission } from "../services/missionService";
import { getValue } from "../services/valueService";
import { getLayananPublished } from "../services/layananService";
import { getActiveTeam } from "../services/teamService";
import PageTemplate from "../Components/PageTemplate";
import "../Styles/Home.css";

export default class Home extends Component {
  state = {
    webUtils: {},
    company_profile: {},
    missions: [],
    values: [],
    layanans: [],
    teams: [],
  };

  getWebUtils = async () => {
    const { data } = await getWebUtils();
    const webUtils = data.result[0];
    this.setState({ webUtils });
  };

  getCompanyProfile = async () => {
    const { data } = await getCompanyProfile();
    const company_profile = data.result[0];
    this.setState({ company_profile });
  };

  getMissions = async () => {
    const { data } = await getMission();
    const missions = data.results;
    this.setState({ missions });
  };

  getValues = async () => {
    const { data } = await getValue();
    const values = data.results;
    this.setState({ values });
  };

  getLayanans = async () => {
    const { data } = await getLayananPublished();
    const layanans = data.results;
    this.setState({ layanans });
  };

  getTeams = async () => {
    const { data } = await getActiveTeam();
    const teams = data.results;
    this.setState({ teams });
  };

  componentDidMount = () => {
    this.getWebUtils();
    this.getCompanyProfile();
    this.getMissions();
    this.getValues();
    this.getLayanans();
    this.getTeams();

    document.title = "BTC - Brilliant Training and Consulting";
  };
  render() {
    const { webUtils, company_profile, missions, values, layanans, teams } =
      this.state;
    return (
      <PageTemplate
        body={
          <div className="container">
            {/* OPENING PAGE */}
            <div className="row mb-5">
              <div className="col-md-8 py-5 order-2 order-md-1">
                <h1
                  dangerouslySetInnerHTML={{ __html: webUtils.Welcome_Title }}
                ></h1>
                <p
                  className="lead"
                  dangerouslySetInnerHTML={{
                    __html: webUtils.Welcome_Subtitle,
                  }}
                ></p>
                <p
                  className="lead"
                  dangerouslySetInnerHTML={{ __html: company_profile.motto }}
                ></p>
                <Link to="/layanan">
                  <button className="btn btn-primary me-2">Layanan Kami</button>
                </Link>
                <Link to="/pelatihan">
                  <button className="btn btn-primary">Pelatihan Kami</button>
                </Link>
              </div>
              <div className="col-md-4 order-1 order-md-2">
                <div className="imageContainer">
                  <img
                    className="LogoImage"
                    src={company_profile.logo1}
                    alt="Brilliant Training and Consulting"
                  />
                </div>
              </div>
            </div>

            <hr />

            {/* VISI */}
            <div className="text-center mb-5">
              <h1>Our Vision</h1>
              <p
                className="lead"
                dangerouslySetInnerHTML={{ __html: company_profile.vision }}
              ></p>
            </div>

            {/* MISI */}
            <div className="text-center mb-5">
              <h1>Our Mission</h1>
              {missions.map((mission) => {
                return (
                  <p
                    className="lead mb-4"
                    key={mission.id}
                    dangerouslySetInnerHTML={{ __html: mission.misi }}
                  ></p>
                );
              })}
            </div>

            <hr />

            {/* VALUES */}
            <div className="row mb-5">
              <h1 className="mb-3 text-center">Our Values</h1>
              {values.map((value) => {
                return (
                  <div className="col-lg-4 text-center mb-3" key={value.id}>
                    <img
                      className="bd-placeholder-img rounded-circle"
                      width="140"
                      height="140"
                      src={value.image}
                      aria-label="Placeholder: 140x140"
                      preserveAspectRatio="xMidYMid slice"
                      focusable="false"
                      title="Competent, Brilliant Training and Consulting"
                      alt="Competent Brilliant Training and Consulting, rawpixel.com freepik.com"
                    />

                    <h2 className="fw-normal">{value.value}</h2>
                  </div>
                );
              })}
            </div>

            <hr />

            {/* LAYANAN */}
            <div className="text-center mb-5">
              <h1 className="mb-3">Layanan Kami</h1>
              {layanans.map((layanan) => {
                return (
                  <p className="lead mb-4" key={layanan.id}>
                    {layanan.title}
                  </p>
                );
              })}
            </div>

            <hr />

            {/* TIM */}
            <div className="text-center mb-5">
              <h1 className="mb-3">Tim Brilliant</h1>
              {teams.map((team, index) => {
                let kelasName = "col-md-7 py-3";
                kelasName += index % 2 === 0 ? "" : " order-md-2";

                let kelasImage = "col-md-5";
                kelasImage += index % 2 === 0 ? "" : " order-md-1";

                let kelasContainerImg = "timContainer";
                kelasContainerImg += index % 2 === 0 ? "" : " float-md-end";
                return (
                  <div key={team.id}>
                    <div className="row mb-3">
                      <div className={kelasName}>
                        <h3>{team.name}</h3>
                        <p
                          className="text-start"
                          dangerouslySetInnerHTML={{ __html: team.experience }}
                        ></p>
                      </div>
                      <div className={kelasImage}>
                        <div className={kelasContainerImg}>
                          <img
                            src={team.photo}
                            alt={
                              team.name + ", Brilliant Training and Consulting"
                            }
                            title={team.name}
                            className="timImage"
                          />
                        </div>
                      </div>
                    </div>

                    <hr className="featurette-divider" />
                  </div>
                );
              })}
            </div>
          </div>
        }
      />
    );
  }
}
