import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getSinglePelatihan } from "../services/pelatihanService";
import PageTemplate from "../Components/PageTemplate";
import "../Styles/PelatihanDetil.css";

export default function PelatihanDetil() {
  const [pelatihan, setPelatihan] = useState({});
  const params = useParams();

  useEffect(() => {
    const getPelatihan = async () => {
      const { data } = await getSinglePelatihan(params.id);
      setPelatihan(data);
    };
    getPelatihan();
  });
  return (
    <PageTemplate
      body={
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="containerBrosur">
                <img
                  src={pelatihan.brosur}
                  className="brosur"
                  alt="BTC"
                  title="Pelatihan BTC"
                />
              </div>
            </div>
            <div className="col-md-6">
              <h3>{pelatihan.title}</h3>
              <p dangerouslySetInnerHTML={{ __html: pelatihan.caption }} />
              <p>Link Registrasi:</p>
              <a href={pelatihan.regLink} target="_blank" rel="noreferrer">
                Klik untuk mendaftar
              </a>
            </div>
          </div>
        </div>
      }
    />
  );
}
