import Axios from "axios";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useParams } from "react-router-dom";

import HeaderKelas from "../Components/HeaderKelas";
import SidebarKelas from "../Components/SidebarKelas";

export default function KelasBerjalan() {
  const [kelasDetil, setKelasDetil] = useState([]);
  const [materi, setMateri] = useState({});
  const params = useParams();

  useEffect(() => {
    const getKelasDetils = () => {
      Axios({
        method: "get",
        url:
          process.env.REACT_APP_APIURL + "/kelasdetil/kelas/" + params.kelasId,
      }).then(async (result) => {
        setKelasDetil(result.data.results);
      });
    };
    getKelasDetils();

    const getSingleMateri = () => {
      Axios({
        method: "get",
        url:
          process.env.REACT_APP_APIURL +
          "/kelasdetil/single/" +
          params.materiId,
      }).then((result) => setMateri(result.data.result[0]));
    };
    getSingleMateri();

    window.scrollTo(0, 0);
  }, [params.materiId, params.kelasId]);

  if (kelasDetil.length === 0)
    return <div className="text-center">Loading...</div>;

  return (
    <div>
      <HeaderKelas data={kelasDetil} />
      <div className="container-fluid">
        <div className="row mt-3 vh-100">
          <SidebarKelas kelasDetil={kelasDetil} />

          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <h3>{materi.title}</h3>
            <div className="coverContainer h-75">
              {materi.video && (
                <ReactPlayer
                  url={materi.video}
                  className="coverImage"
                  width="100%"
                  height="100%"
                  alt="Manajemen Ruang Rawat, Brilliant Training and Consulting"
                  title="Manajemen Ruang Rawat"
                  controls={true}
                />
              )}
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
