import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getSingleLayanan } from "../services/layananService";
import PageTemplate from "../Components/PageTemplate";

export default function LayananDetil() {
  const [layanan, setLayanan] = useState({});
  const params = useParams();

  useEffect(() => {
    const getData = async () => {
      const { data } = await getSingleLayanan(params.id);
      document.title = "BTC - " + data.result[0].title;
      setLayanan(data.result[0]);
    };
    getData();

    window.scrollTo(0, 0);
  }, [params.id]);
  return (
    <PageTemplate
      body={
        <div className="container mb-5">
          {/* JUDUL HALAMAN */}
          <section className="py-2 text-center container">
            <h1 className="">{layanan.title}</h1>
          </section>
          <div className="row">
            <div className="col-md-6">
              <img
                src={layanan.picture}
                alt="Brilliant Training and Consulting"
                title={layanan.title}
                className="Cover w-100 h-75 rounded-4"
              />
            </div>
            <div className="col-md-6">
              <div className="author">
                <p
                  className="text-muted"
                  dangerouslySetInnerHTML={{ __html: layanan.subTitle }}
                ></p>
              </div>
              <div className="content">
                <p
                  dangerouslySetInnerHTML={{ __html: layanan.description }}
                ></p>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
}
