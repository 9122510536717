import React from "react";
import { Link } from "react-router-dom";

export default function SidebarKelas({ kelasDetil }) {
  return (
    <nav
      id="sidebarMenu"
      className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse"
    >
      <div className="position-sticky pt-3">
        <ul className="nav flex-column">
          {kelasDetil.map((detil) => {
            return (
              <li className="nav-item" key={detil.id}>
                <Link
                  to={"/kelas/" + detil.Kela.id + "/materi/" + detil.id}
                  className="nav-link active"
                  aria-current="page"
                  href="#"
                >
                  <span
                    data-feather="home"
                    className="align-text-bottom"
                  ></span>
                  {detil.title}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </nav>
  );
}
