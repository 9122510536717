import http from "./httpService";

const apiURL = process.env.REACT_APP_APIURL + "/companyprofile";

export function getCompanyProfile() {
  return http.get(apiURL);
}

export function getBrandImage() {
  return http.get(apiURL + "/logo");
}
