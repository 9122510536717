import http from "./httpService";

const apiURL = process.env.REACT_APP_APIURL + "/gallery";

export function getGalleries() {
  return http.get(apiURL);
}

export function getSingleGallery(id) {
  return http.get(apiURL + "/single/" + id);
}
