import http from "./httpService";

const apiUrl = process.env.REACT_APP_APIURL + "/pelatihan";

export function getAllPelatihan() {
  return http.get(apiUrl);
}

export function getSinglePelatihan(id) {
  return http.get(apiUrl + "/single/" + id);
}

export function getPelatihanNotDone() {
  return http.get(apiUrl + "/notdone");
}
