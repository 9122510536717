import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMailBulk,
  faMapLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

import { getCompanyProfile } from "../services/companyProfileService";
import PageTemplate from "../Components/PageTemplate";

export default class Kontak extends Component {
  state = {
    company_profile: {},
  };

  getCompanyProfile = async () => {
    const { data } = await getCompanyProfile();
    const company_profile = data.result[0];
    this.setState({ company_profile });
  };

  componentDidMount = () => {
    this.getCompanyProfile();

    // page title
    document.title = "BTC - Kontak Brilliant";
  };
  render() {
    const { company_profile } = this.state;
    return (
      <PageTemplate
        body={
          <div className="container mb-5">
            <div className="row justify-content-center">
              <div className="col-md-6 text-center">
                <div className="strOrgContainer mb-3">
                  <img
                    src={company_profile.officePicture}
                    alt="Kantor Sekretariat, Brilliant Training and Consulting"
                    title="Kantor Brilliant Training and Consulting"
                    className="strOrgImage"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-1">
                    <FontAwesomeIcon icon={faMapLocationDot} />
                  </div>
                  <div className="col-9">
                    <span>{company_profile.address_room}</span>
                    <p>{company_profile.address_building}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-1">
                    <FontAwesomeIcon icon={faWhatsapp} />
                  </div>
                  <div className="col-9">
                    <p>{company_profile.phone}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-1">
                    <FontAwesomeIcon icon={faMailBulk} />
                  </div>
                  <div className="col-9">
                    <p>{company_profile.email}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
    );
  }
}
