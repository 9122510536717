import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faMailBulk } from "@fortawesome/free-solid-svg-icons";

import "../Styles/Footer.css";
import { getCompanyProfile } from "../services/companyProfileService";

export default function Footer() {
  const [profile, setProfile] = useState({});
  const [phone, setPhone] = useState("");

  useEffect(() => {
    const getProfile = async () => {
      const { data } = await getCompanyProfile();
      setProfile(data.result[0]);
      setPhone(data.result[0].phone.substring(1));
    };
    getProfile();
  }, []);
  return (
    <footer className="container text-center">
      <div className="d-flex justify-content-center mb-3">
        <a
          href={profile.instagram}
          title="instagram BTC"
          target="_blank"
          className="text-body"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faInstagram} size="2x" className="me-3" />
        </a>
        <a
          href={"https://wa.me/62" + phone}
          title="kirim pesan whatsapp"
          target="_blank"
          className="text-body"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faWhatsapp} size="2x" className="me-3" />
        </a>
        <a
          href={
            "https://mail.google.com/mail/?view=cm&fs=1&to=" + profile.email
          }
          title="kirim pesan email"
          target="_blank"
          className="text-body"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faMailBulk} size="2x" />
        </a>
      </div>
      <p onClick={() => window.scrollTo(0, 0)} className="backToTop">
        Back to top
      </p>
      <p>&copy; Yushamdev</p>
    </footer>
  );
}
