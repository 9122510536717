import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Link, useParams } from "react-router-dom";

import "../Styles/HeaderKelas.css";

export default function HeaderKelas({ data }) {
  const [nextMateri, setNextMateri] = useState(0);
  const { kelasId, materiId } = useParams();

  useEffect(() => {
    let arrMateri = [...data];
    let index = arrMateri.findIndex(
      (materi) => materi.id === parseInt(materiId)
    );
    if (index === arrMateri.length - 1) {
      setNextMateri(0);
    } else {
      setNextMateri(data[index + 1].id);
    }
  }, [data, nextMateri, materiId]);

  return (
    <header className="navbar navbar-dark sticky-top bg-primary flex-md-nowrap p-0 shadow">
      <div className="justify-content-between">
        <Link
          to="/"
          className="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6 p-3 mx-3"
          title="Back to Home"
        >
          <FontAwesomeIcon icon={faHome} />
        </Link>
        <button
          className="navbar-toggler d-md-none collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#sidebarMenu"
          aria-controls="sidebarMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
      </div>
      <div className="navbar-nav mx-3">
        <div className="nav-item text-nowrap">
          {nextMateri === 0 ? (
            <button className="btn btn-outline-secondary px-3 text-white border border-white">
              Kelas Selesai <FontAwesomeIcon icon={faChevronRight} />
            </button>
          ) : (
            <Link to={"/kelas/" + kelasId + "/materi/" + nextMateri}>
              <button className="btn btn-outline-secondary px-3 text-white border border-white">
                Complete and Continue <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </Link>
          )}
        </div>
      </div>
    </header>
  );
}
