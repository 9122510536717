import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCirclePlay,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import { useLocation } from "react-router-dom";
import ReactPlayer from "react-player";

import Header from "../Components/Header";
import Footer from "../Components/Footer";
import "../Styles/KelasPreview.css";

export default function KelasPreview() {
  const location = useLocation();
  const [kelas, setKelas] = useState({});
  const [kelasDetil, setKelasDetil] = useState([]);
  const [kelasDetilShow, setKelasDetilShow] = useState([]);
  const [currentVideo, setCurrentVideo] = useState("");

  const changeVideo = (video) => {
    setCurrentVideo(video);
  };

  useEffect(() => {
    const getKelas = () => {
      Axios({
        method: "get",
        url:
          process.env.REACT_APP_APIURL + "/kelas/single/" + location.state.id,
      }).then((result) => {
        setKelas(result.data.result[0]);
        setKelasDetil(result.data.result[0].KelasDetils);
        setKelasDetilShow(result.data.result[0].KelasDetils.slice(0, 3));
        setCurrentVideo(result.data.result[0].KelasDetils[0].video);
      });
    };
    getKelas();
  }, [location.state.id]);
  if (Object.keys(kelas).length === 0) return <p>Loading...</p>;
  return (
    <div>
      <Header />
      <main>
        <div className="container mb-5">
          {/* JUDUL HALAMAN */}
          <section className="py-4 text-center container">
            <h1 className="">{kelas.title}</h1>
            <p className="lead text-muted">{kelas.subTitle}</p>
            <div className="row justify-content-center">
              <div className="col-6 col-md-2 text-center">
                <p className="fs-4">Peserta</p>
                <p className="fs-4">
                  <strong>1234</strong> orang
                </p>
              </div>
              <div className="col-6 col-md-2 text-center">
                <p className="fs-4">Sertifikat</p>
                <p>
                  <FontAwesomeIcon icon={faCheckCircle} size="2x" />
                </p>
              </div>
              <div className="col-6 col-md-2 text-center">
                <p className="fs-4">Konsultansi</p>
                <p>
                  <FontAwesomeIcon icon={faCheckCircle} size="2x" />
                </p>
              </div>
            </div>
          </section>

          {/* COVER */}
          <div className="row">
            <div className="col-md-8">
              <div className="coverContainer">
                <ReactPlayer
                  url={currentVideo}
                  className="coverImage"
                  width="100%"
                  height="500px"
                  alt="Manajemen Ruang Rawat, Brilliant Training and Consulting"
                  title="Manajemen Ruang Rawat"
                  controls={true}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="containerSilabus p-4 d-flex flex-column">
                <div className="mb-3">
                  <span>{kelasDetil.length} Judul Materi</span>
                </div>
                {kelasDetilShow.map((detil) => {
                  return (
                    <div
                      className="containerJudul p-4 d-flex align-items-center justify-content-between mb-3"
                      key={detil.id}
                      onClick={() => changeVideo(detil.video)}
                    >
                      <div className="d-flex align-items-center p-2">
                        <FontAwesomeIcon
                          icon={faCirclePlay}
                          size="1x"
                          className="me-2"
                        />
                        <div className="">{detil.title}</div>
                      </div>
                    </div>
                  );
                })}
                <div className="containerJudul p-4 d-flex align-items-center justify-content-between mb-3">
                  <div className="d-flex align-items-center p-2">
                    <FontAwesomeIcon
                      icon={faCirclePlay}
                      size="1x"
                      className="me-2"
                    />
                    <div className="">
                      {kelasDetil.length - kelasDetilShow.length} Judul Materi
                      Lainnya
                    </div>
                  </div>
                </div>
                <button className="btn btn-primary buttonKelasPrev mt-auto">
                  Daftar Kelas
                </button>
              </div>
            </div>
          </div>
          <hr />

          {/* AREA BELOW COVER */}
          <ul className="nav nav-pills">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="tentang-tab"
                data-bs-toggle="tab"
                data-bs-target="#tentang"
                type="button"
              >
                Tentang Kelas
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="tools-tab"
                data-bs-toggle="tab"
                data-bs-target="#tools"
                type="button"
              >
                Peralatan
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="testimoni-tab"
                data-bs-toggle="tab"
                data-bs-target="#testimoni"
                type="button"
              >
                Testimoni
              </button>
            </li>
          </ul>

          {/* TAB CONTENT FOR TENTANG TAB */}
          <div className="tab-content mb-5">
            <div
              className="tab-pane active"
              id="tentang"
              role="tabpanel"
              aria-labelledby="tentang-tab"
            >
              <div className="p-3">
                <div className="row mb-3">
                  <div className="col-md-8">
                    <p>{kelas.about_class}</p>
                  </div>
                </div>

                <div className="note mb-3">
                  <h5>
                    <strong>Catatan:</strong>
                  </h5>
                  <p>{kelas.note}</p>
                </div>

                <h5>
                  <strong>Output Pelatihan</strong>
                </h5>
                <p>
                  <strong>Tujuan Umum</strong>
                </p>
                <p>{kelas.output_umum}</p>
                <p>
                  <strong>Tujuan Khusus</strong>
                </p>
                <span>Setelah mengikuti pelatihan ini, peserta mampu</span>
                <ul className="tujuanKhusus">
                  <li>{kelas.output_khusus}</li>
                  <li>Membuat regulasi pelayanan rawat inap di RS</li>
                  <li>
                    Menyusun perencanaan kegiatan pelayanan rawat inap di rumah
                    sakit.
                  </li>
                  <li>
                    Melakukan penggerakan dan pelaksanaan kegiatan pelayanan
                    rawat inap di rumah sakit.
                  </li>
                  <li>
                    Melakukan pengawasan, pengendalian dan evaluasi kegiatan
                    pelayanan rawat inap di rumah sakit.
                  </li>
                </ul>
              </div>
            </div>

            {/* TAB CONTENT FOR TOOLS TAB */}
            <div
              className="tab-pane"
              id="tools"
              role="tabpanel"
              aria-labelledby="tools-tab"
            >
              <div className="p-3">
                <div className="row">
                  <div className="col-md-8">
                    <p>Peralatan yang digunakan selama pelatihan ini adalah:</p>
                    <ul className="peralatanKelas">
                      <li>{kelas.tools}</li>
                      <li>Buku tulis dan pulpen</li>
                      <li>Laptop atau komputer</li>
                      <li>Dan peralatan lainnya</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* TAB CONTENT FOR TESTIMONI TAB */}
            <div
              className="tab-pane"
              id="testimoni"
              role="tabpanel"
              aria-labelledby="testimoni-tab"
            >
              <div className="p-3">
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                  {kelas.Testimonis === undefined
                    ? null
                    : kelas.Testimonis.map((testimoni) => {
                        var stars = [];
                        for (var i = 0; i < testimoni.rate; i++) {
                          stars.push(
                            <FontAwesomeIcon
                              icon={faStar}
                              className="text-warning"
                              key={i}
                            />
                          );
                        }
                        return (
                          <div className="col" key={testimoni.id}>
                            <div className="card shadow-sm">
                              <div className="card-body">
                                <p className="lead">{testimoni.name}</p>
                                <p className="card-text">
                                  {testimoni.testimoni}
                                </p>
                                <div className="d-flex float-end">{stars}</div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                </div>
              </div>
            </div>
          </div>

          <hr />

          {/* PRICING */}
          <section className="py-4 text-center container">
            <div className="row py-lg-4">
              <div className="col-lg-6 col-md-8 mx-auto">
                <h1 className="">Nilai Investasi</h1>
                <p className="lead text-muted">
                  Tanamkan investasi Anda dengan mengikuti kelas ini, dengan
                  harga spesial dari kami
                </p>
              </div>
            </div>
          </section>
          <div className="row row-cols-1 row-cols-md-3 mb-3 justify-content-center text-center">
            <div className="col">
              <div className="card mb-4 rounded-3 shadow-sm">
                <div className="card-header py-3">
                  <h4 className="my-0 fw-normal">Reguler</h4>
                </div>
                <div className="card-body">
                  <h1 className="card-title pricing-card-title">
                    {currencyFormat(kelas.harga)}
                    <small className="text-muted fw-light"> selamanya</small>
                  </h1>
                  <ul className="list-unstyled mt-3 mb-4">
                    <li>Sertifikat Ikut Serta</li>
                    <li>Konsultansi dengan para pengajar</li>
                    <li>Free file-file yang diperlukan</li>
                    <li>24 jam akses video dimana saja</li>
                  </ul>
                  <button
                    type="button"
                    className="w-100 btn btn-lg btn-primary"
                  >
                    Daftar Kelas
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <hr />
      <Footer />
    </div>
  );
}
const currencyFormat = (num) => {
  return "Rp " + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};
