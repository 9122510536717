import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

import { getMadings } from "../services/madingService";
import PageTemplate from "../Components/PageTemplate";
import Pagination from "../Components/Pagination";
import { paginate } from "../Utils/Paginate";
import "../Styles/MadingDetil.css";

export default class Mading extends Component {
  state = {
    currentPage: 1,
    pageSize: 9,
    madings: [],
  };

  getMadings = async () => {
    const { data } = await getMadings();
    const madings = data.results;
    this.setState({ madings });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  truncateContent = (content, size) => {
    var plaintext = content.replace(/(<([^>]+)>)/gi, "");
    return plaintext.length > size
      ? plaintext.slice(0, size - 1) + "..."
      : plaintext;
  };

  componentDidMount = () => {
    this.getMadings();
    document.title = "BTC - Mading Brilliant";
  };
  render() {
    const { currentPage, madings: allMadings, pageSize } = this.state;
    const madings = paginate(allMadings, currentPage, pageSize);
    return (
      <PageTemplate
        pageTitle="Mading Brilliant"
        pageSubTitle="Informasi seputar Brilliant yang wajib kamu tahu"
        body={
          <>
            <div className="album">
              <div className="container">
                {madings.length === 0 ? (
                  <div className="text-center">
                    <p>
                      Maaf, belum ada informasi yang dapat kami bagikan kepada
                      Anda
                    </p>
                  </div>
                ) : (
                  madings.map((mading) => {
                    return (
                      <div className="row my-2" key={mading.id}>
                        <div className="col-md-2">
                          <div className="imgMadingContainer">
                            <img
                              src={mading.image}
                              alt="Brilliant Training and Consulting"
                              className="imgMading2"
                            />
                          </div>
                        </div>
                        <div className="col-md-10 mb-4">
                          <Link
                            to={"/mading/" + mading.id + "/" + mading.title2}
                          >
                            <h5>{mading.title}</h5>
                          </Link>
                          <p>{this.truncateContent(mading.content, 255)}</p>
                          <div className="d-flex text-muted">
                            <span className="me-1">
                              <FontAwesomeIcon icon={faEye} />
                            </span>
                            <p className="me-2">{mading.klik}</p>
                            <p className="me-2">|</p>
                            <p className="me-1">
                              Kategori: {mading.Kategori.kategori}
                            </p>
                          </div>
                        </div>
                        <hr />
                      </div>
                    );
                  })
                )}
              </div>
            </div>
            <Pagination
              currentPage={currentPage}
              itemsCount={allMadings.length}
              pageSize={pageSize}
              onPageChange={this.handlePageChange}
            />
          </>
        }
      />
    );
  }
}
