import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Axios from "axios";

export default class MyKelas extends Component {
  state = {
    kelases: [],
  };

  getKelas = () => {
    Axios({
      method: "get",
      url: process.env.REACT_APP_APIURL + "/kelas/published",
    }).then((result) => this.setState({ kelases: result.data.results }));
  };

  componentDidMount = () => {
    this.getKelas();
  };
  render() {
    const { kelases } = this.state;
    return (
      <div>
        <Header />
        <main>
          <section className="py-4 text-center container">
            <div className="row py-lg-4">
              <div className="col-lg-6 col-md-8 mx-auto">
                <h1 className="fw-light">Daftar Kelasku</h1>
                <p className="lead text-muted">
                  <i>
                    Kepakkan sayapmu setinggi langit, dan terbanglah di atas
                    awan
                  </i>
                </p>
              </div>
            </div>
          </section>

          <div className="album py-5">
            <div className="container">
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 justify-content-center">
                {kelases.length === 0 ? (
                  <div className="text-center">
                    <p>Maaf, Anda belum mendaftar kelas manapun.</p>
                    <p>
                      Silahkan klik link <Link to="/kelas">ini</Link> untuk
                      memilih kelas
                    </p>
                  </div>
                ) : (
                  kelases.map((kelas) => {
                    let rating = Math.ceil(kelas.Testimonis[0].avgRate);
                    var stars = [];
                    for (var i = 0; i < rating; i++) {
                      stars.push(
                        <FontAwesomeIcon
                          icon={faStar}
                          className="text-warning"
                          key={i}
                        />
                      );
                    }
                    return (
                      <div className="col" key={kelas.id}>
                        <div className="card shadow-sm">
                          <img
                            className="bd-placeholder-img card-img-top"
                            width="100%"
                            height="225"
                            src={kelas.cover}
                            aria-label="Placeholder: Thumbnail"
                            preserveAspectRatio="xMidYMid slice"
                            focusable="false"
                          />

                          <div className="card-body">
                            <h5 className="card-title">{kelas.title}</h5>
                            <p className="card-text">{kelas.subTitle}</p>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="d-flex">{stars}</div>
                              <Link to="/kelas/1/materi/1">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-primary"
                                >
                                  Masuk kelas
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </main>
        <hr />
        <Footer />
      </div>
    );
  }
}
