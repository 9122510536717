import React, { Component } from "react";

import { getCompanyProfile } from "../services/companyProfileService";
import { getStrukturOrganisasi } from "../services/strukturOrgService";
import PageTemplate from "../Components/PageTemplate";
import "../Styles/Tentang.css";

export default class Tentang extends Component {
  state = {
    company_profile: {},
    struktur_organisasi: [],
  };

  getCompanyProfile = async () => {
    const { data } = await getCompanyProfile();
    const company_profile = data.result[0];
    this.setState({ company_profile });
  };

  getStrukturOrganisasi = async () => {
    const { data } = await getStrukturOrganisasi();
    const struktur_organisasi = data.results;
    this.setState({ struktur_organisasi });
  };

  componentDidMount = () => {
    this.getCompanyProfile();
    this.getStrukturOrganisasi();

    // page title
    document.title = "BTC - Tentang Brilliant Training and Consulting";
  };
  render() {
    const { company_profile, struktur_organisasi } = this.state;
    return (
      <PageTemplate
        body={
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <div className="logoContainer float-md-end">
                  <img
                    src={company_profile.logo1}
                    alt="Logo Brilliant Training and Consulting"
                    title="Logo Brilliant Training and Consulting"
                    className="logoImage"
                  />
                </div>
              </div>
              <div className="col-md-8">
                <h3>{company_profile.company_name}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: company_profile.about_company,
                  }}
                ></p>
              </div>
            </div>
            <hr />
            <h3 className="text-center mb-5">Struktur Organisasi</h3>
            {struktur_organisasi.map((employee) => {
              return (
                <div key={employee.id}>
                  <div className="row mb-3">
                    <div className="col-md-5">
                      <div className="strOrgContainer text-center">
                        <img
                          src={employee.photo}
                          alt="Siti Komariah, Brilliant Training and Consulting"
                          title="Ns. Siti Komariah, S.Kep., MARS"
                          className="strOrgImage"
                        />
                      </div>
                    </div>
                    <div className="col-md-7 text-center text-md-start">
                      <h4 className="my-3">{employee.name}</h4>
                      <p className="text-muted lead ">{employee.jabatan}</p>
                    </div>
                  </div>

                  <hr className="featurette-divider" />
                </div>
              );
            })}
          </div>
        }
      />
    );
  }
}
