import React, { Component } from "react";
import { Link } from "react-router-dom";

import { getLayananPublished } from "../services/layananService";
import PageTemplate from "../Components/PageTemplate";
import "../Styles/Layanan.css";

export default class Layanan extends Component {
  state = {
    layanans: [],
  };

  getLayanans = async () => {
    const { data } = await getLayananPublished();
    const layanans = data.results;
    this.setState({ layanans });
  };

  componentDidMount = () => {
    this.getLayanans();
    document.title = "BTC - Layanan Brilliant";
  };
  render() {
    const { layanans } = this.state;
    return (
      <PageTemplate
        pageTitle="Layanan Brilliant"
        pageSubTitle="Jasa apa saja yang diberikan oleh Brilliant?"
        body={
          <div className="album">
            <div className="container">
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 justify-content-center">
                {layanans.map((layanan) => {
                  return (
                    <div className="col" key={layanan.id}>
                      <div className="card shadow cardLayanan">
                        <img
                          className="bd-placeholder-img card-img-top"
                          width="100%"
                          height="225"
                          src={layanan.picture}
                          aria-label="Placeholder: Thumbnail"
                          preserveAspectRatio="xMidYMid slice"
                          focusable="false"
                        />

                        <div className="card-body d-flex flex-column">
                          <h5 className="card-title">{layanan.title}</h5>
                          <div className="mt-auto">
                            <Link
                              to={
                                "/layanan/" + layanan.id + "/" + layanan.title2
                              }
                            >
                              <button
                                type="button"
                                className="btn btn-sm btn-primary float-end"
                              >
                                Lihat detil
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        }
      />
    );
  }
}
