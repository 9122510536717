import React, { useEffect, useState } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";

import { getSingleGallery } from "../services/galeriService";
import PageTemplate from "../Components/PageTemplate";
import "../Styles/GaleriDetil.css";

export default function GaleriDetil() {
  const [gallery, setGallery] = useState({});
  const params = useParams();

  useEffect(() => {
    const getGallery = async () => {
      const { data } = await getSingleGallery(params.id);
      document.title = "BTC - " + data.result[0].title;
      setGallery(data.result[0]);
    };

    getGallery();

    window.scrollTo(0, 0);
  }, [params.id]);
  if (Object.keys(gallery).length === 0) return <p>Loading...</p>;
  return (
    <PageTemplate
      body={
        <div className="container mb-5">
          <section className="py-2 text-center container"></section>
          <div className="mb-3">
            <div
              id="carouselBTC"
              className="carousel slide"
              data-bs-ride="true"
            >
              <div className="carousel-indicators">
                {gallery.GalleryImages.map((image, index) => {
                  let kelasButton = "";
                  kelasButton += index === 0 ? "active" : "";
                  return (
                    <button
                      type="button"
                      data-bs-target="#carouselBTC"
                      data-bs-slide-to={index}
                      className={kelasButton}
                      aria-current="true"
                      aria-label={"Slide " + image.id}
                      key={index}
                    ></button>
                  );
                })}
              </div>
              <div className="carousel-inner ">
                {gallery.GalleryImages.map((image, index) => {
                  let kelasCarousel = "carousel-item";
                  kelasCarousel += index === 0 ? " active" : "";
                  return (
                    <div className={kelasCarousel} key={index}>
                      <img
                        className="bd-placeholder-img"
                        width="100%"
                        height="100%"
                        src={image.image}
                        aria-hidden="true"
                        preserveAspectRatio="xMidYMid slice"
                        focusable="false"
                        alt="BTC Image"
                        title="BTC Image"
                      />
                    </div>
                  );
                })}
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselBTC"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselBTC"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div className="">
            <p className="dateBTC">
              {moment(gallery.date).format("DD/MM/YYYY")}
            </p>
          </div>
          <div className="content">
            <p dangerouslySetInnerHTML={{ __html: gallery.caption }}></p>
          </div>
        </div>
      }
    />
  );
}
