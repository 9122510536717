import React from "react";
import Header from "./Header";
import Footer from "./Footer";

export default function PageTemplate({ body, pageTitle, pageSubTitle }) {
  return (
    <div>
      <Header />
      <main className="mb-4">
        {pageTitle && (
          <section className="py-2 text-center container">
            <div className="row py-lg-2">
              <div className="col-lg-6 col-md-8 mx-auto">
                <h1 className="fw-light">{pageTitle}</h1>
                <p className="lead text-muted">{pageSubTitle}</p>
              </div>
            </div>
          </section>
        )}
        {body}
      </main>
      <Footer />
    </div>
  );
}
