import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { logEvent } from "firebase/analytics";

import Home from "./Pages/Home";
import Kelas from "./Pages/Kelas";
import Kontak from "./Pages/Kontak";
import Layanan from "./Pages/Layanan";
import Tentang from "./Pages/Tentang";
import KelasPreview from "./Pages/KelasPreview";
import Galeri from "./Pages/Galeri";
import Mading from "./Pages/Mading";
import MadingDetil from "./Pages/MadingDetil";
import LayananDetil from "./Pages/LayananDetil";
import GaleriDetil from "./Pages/GaleriDetil";
import KelasBerjalan from "./Pages/KelasBerjalan";
import MyKelas from "./Pages/MyKelas";
import NotFound from "./Pages/NotFound";
import fbConfig from "./Config/firebaseConfig";
import Pelatihan from "./Pages/Pelatihan";
import PelatihanDetil from "./Pages/PelatihanDetil";

library.add(fab, fas);

export default class App extends Component {
  componentDidMount = () => {
    const analytics = fbConfig.analytics;
    logEvent(analytics, "notification_received");
  };
  render() {
    return (
      <Routes>
        <Route path="/" element={<Home />} />

        {/* Route Tentang Brilliant */}
        <Route path="/tentang" element={<Tentang />} />

        {/* Route Kelas Brilliant */}
        <Route path="/kelas" element={<Kelas />} />
        <Route path="/preview/kelas/:title" element={<KelasPreview />} />
        <Route
          path="/kelas/:kelasId/materi/:materiId"
          element={<KelasBerjalan />}
        />
        <Route path="/kelasku" element={<MyKelas />} />

        {/* Route Layanan Brilliant */}
        <Route path="/layanan" element={<Layanan />} />
        <Route path="/layanan/:id/:title" element={<LayananDetil />} />

        {/* Route Pelatihan */}
        <Route path="/pelatihan" element={<Pelatihan />} />
        <Route path="/pelatihan/:id/:title" element={<PelatihanDetil />} />

        {/* Route Kontak Brilliant */}
        <Route path="/kontak" element={<Kontak />} />

        {/* Route Gallery Brilliant */}
        <Route path="/galeri" element={<Galeri />} />
        <Route path="/galeri/:id" element={<GaleriDetil />} />

        {/* Route Mading Brilliant */}
        <Route path="/mading" element={<Mading />} />
        <Route path="/mading/:id/:title" element={<MadingDetil />} />

        {/* Route Signup */}

        {/* Route Not Found */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    );
  }
}
