import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import PageTemplate from "../Components/PageTemplate";
import { getPelatihanNotDone } from "../services/pelatihanService";
import Pagination from "../Components/Pagination";
import { paginate } from "../Utils/Paginate";
import "../Styles/Pelatihan.css";

export default function Pelatihan() {
  const [allPelatihans, setAllPelatihans] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(8);

  const getPelatihans = async () => {
    const { data } = await getPelatihanNotDone();
    setAllPelatihans(data);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getPelatihans();
  }, []);
  const pelatihans = paginate(allPelatihans, currentPage, pageSize);
  return (
    <PageTemplate
      pageTitle="Pelatihan BTC"
      pageSubTitle="Tingkatkan softskill dan hardskill mu dengan mengikuti pelatihan kami"
      body={
        <>
          <div className="container">
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 g-3 justify-content-center">
              {pelatihans.length === 0 ? (
                <h5 className="text-center">
                  Yah belum ada jadwal pelatihan nih. Sabar ya
                </h5>
              ) : (
                pelatihans.map((pelatihan) => {
                  return (
                    <div className="col" key={pelatihan.id}>
                      <div className="card my-2 shadow cardPelatihan">
                        <img
                          src={pelatihan.brosur}
                          className="card-img-top p-2"
                          alt="BTC"
                          width="100%"
                          height="250"
                        />
                        <div className="card-body d-flex flex-column">
                          <h5 className="card-title">{pelatihan.title}</h5>
                          <div className="mt-auto d-flex justify-content-between align-items-center">
                            <div className="">
                              <p>
                                Status:{" "}
                                {pelatihan.status === "published"
                                  ? "Open"
                                  : pelatihan.status === "hold"
                                  ? "Ditunda"
                                  : null}
                              </p>
                              <p className="text-muted dateBTC">
                                {moment(pelatihan.date).format("LL")}
                              </p>
                            </div>
                            <Link
                              to={
                                "/pelatihan/" +
                                pelatihan.id +
                                "/" +
                                pelatihan.title2
                              }
                              className="btn btn-primary"
                            >
                              Lihat Detil
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
          <Pagination
            currentPage={currentPage}
            itemsCount={allPelatihans.length}
            pageSize={pageSize}
            onPageChange={handlePageChange}
          />
        </>
      }
    />
  );
}
