import React, { Component } from "react";
import moment from "moment";
import { Link } from "react-router-dom";

import Pagination from "../Components/Pagination";
import { paginate } from "../Utils/Paginate";
import { getGalleries } from "../services/galeriService";
import PageTemplate from "../Components/PageTemplate";
import "../Styles/Gallery.css";

export default class Galeri extends Component {
  state = {
    currentPage: 1,
    pageSize: 9,
    galleries: [],
  };

  getGalleries = async () => {
    const { data } = await getGalleries();
    const galleries = data.results;
    this.setState({ galleries });
  };

  componentDidMount = () => {
    this.getGalleries();

    // page title
    document.title = "BTC - Galeri Brilliant";
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };
  render() {
    const { currentPage, galleries: allGalleries, pageSize } = this.state;

    const galleries = paginate(allGalleries, currentPage, pageSize);

    return (
      <PageTemplate
        pageTitle="Galeri Brilliant"
        pageSubTitle="Dokumentasi kegiatan Brilliant Training and Consulting"
        body={
          <>
            <div className="album">
              <div className="container">
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 justify-content-center">
                  {galleries.length === 0 ? (
                    <div className="text-center">
                      <p>Yah, dokumentasinya masih belum ada nih</p>
                    </div>
                  ) : (
                    galleries.map((gallery) => {
                      return (
                        <div className="col" key={gallery.id}>
                          <div className="card shadow cardLayanan">
                            <img
                              className="bd-placeholder-img card-img-top"
                              width="100%"
                              height="225"
                              src={gallery.GalleryImages[0].image}
                              aria-label="Placeholder: Thumbnail"
                              preserveAspectRatio="xMidYMid slice"
                              focusable="false"
                            />

                            <div className="card-body d-flex flex-column">
                              <p className="card-text">{gallery.title}</p>
                              <div className="mt-auto d-flex justify-content-between align-items-center">
                                <p className="text-muted dateBTC">
                                  {moment(gallery.date).format("DD/MM/YYYY")}
                                </p>
                                <Link to={"/galeri/" + gallery.id}>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-primary"
                                  >
                                    Lihat detil
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
            </div>
            <Pagination
              currentPage={currentPage}
              itemsCount={allGalleries.length}
              pageSize={pageSize}
              onPageChange={this.handlePageChange}
            />
          </>
        }
      />
    );
  }
}
