import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

import {
  getMadingByKategori,
  getSingleMading,
  putTambahView,
} from "../services/madingService";
import PageTemplate from "../Components/PageTemplate";
import "../Styles/MadingDetil.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

export default function MadingDetil() {
  const [singleMading, setMading] = useState({});
  const [madings, setMadings] = useState([]);
  const params = useParams();

  useEffect(() => {
    // get single Mading
    const getMadingById = async () => {
      const mading = await getSingleMading(params.id);

      //tambah view
      await putTambahView(params.id, mading.data.result[0]);
      setMading(mading.data.result[0]);

      // search related kategori
      const madings = await getMadingByKategori(
        mading.data.result[0].KategoriId
      );
      setMadings(madings.data.results);
    };
    getMadingById();

    window.scrollTo(0, 0);
  }, [params.id]);

  var carouselOptions = {
    loop: true,
    margin: 10,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
  };
  return (
    <PageTemplate
      body={
        <>
          <div className="container mb-2 border-bottom">
            {/* JUDUL HALAMAN */}
            <section className="py-2 text-center container">
              <h1 className="">{singleMading.title}</h1>
            </section>
            <div className="containerImgMading mb-3">
              <img
                src={singleMading.image}
                alt="Brilliant Training and Consulting"
                title={singleMading.title}
                className="imgMading"
              />
            </div>
            <div className="author">
              <p className="fs-6">Penulis: {singleMading.author}</p>
            </div>
            <div className="content">
              <p dangerouslySetInnerHTML={{ __html: singleMading.content }}></p>
            </div>
          </div>
          <div className="container mb-5">
            <h3>Artikel lain</h3>
            <OwlCarousel className="owl-theme" {...carouselOptions}>
              {madings.map((singleMading) => {
                return (
                  <div
                    className="card shadow-sm cardLayanan"
                    key={singleMading.id}
                  >
                    <img
                      className="bd-placeholder-img card-img-top"
                      width="100%"
                      height="225"
                      src={singleMading.image}
                      aria-label="Placeholder: Thumbnail"
                      preserveAspectRatio="xMidYMid slice"
                      focusable="false"
                    />

                    <div className="card-body d-flex flex-column">
                      <h5 className="card-title">{singleMading.title}</h5>
                      <div className="mt-auto d-flex justify-content-between align-items-center">
                        <div className="d-flex text-muted">
                          <span className="me-1">
                            <FontAwesomeIcon icon={faEye} />
                          </span>
                          <p>{singleMading.klik}</p>
                        </div>
                        <Link
                          to={
                            "/mading/" +
                            singleMading.id +
                            "/" +
                            singleMading.title2
                          }
                        >
                          <button
                            type="button"
                            className="btn btn-sm btn-primary float-end"
                          >
                            Lihat detil
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
            </OwlCarousel>
          </div>
        </>
      }
    />
  );
}
