import http from "./httpService";

const apiURL = process.env.REACT_APP_APIURL + "/layanan";

export function getLayananPublished() {
  return http.get(apiURL + "/published");
}

export function getSingleLayanan(id) {
  return http.get(apiURL + "/single/" + id);
}
