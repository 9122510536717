import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getBrandImage } from "../services/companyProfileService";

export default function Header() {
  const [logo, setLogo] = useState("");

  useEffect(() => {
    const getLogo = async () => {
      const { data } = await getBrandImage();
      setLogo(data.result[0]);
    };
    getLogo();
  }, []);

  return (
    <div className="container">
      <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 border-bottom">
        <Link
          to="/"
          className="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none bg-transparent"
          title="Home"
        >
          <img
            className="bi me-2"
            width="115"
            height="50"
            src={logo.logo2}
            aria-label="Bootstrap"
          />
        </Link>

        <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
          <li>
            <Link
              to="/tentang"
              className="nav-link px-2 link-dark"
              title="Tentang Brilliant"
            >
              Tentang Brilliant
            </Link>
          </li>
          <li>
            <Link
              to="/layanan"
              className="nav-link px-2 link-dark"
              title="Majalah Dinding"
            >
              Layanan
            </Link>
          </li>
          <li>
            <Link
              to="/pelatihan"
              className="nav-link px-2 link-dark"
              title="Pelatihan BTC"
            >
              Pelatihan
            </Link>
          </li>
          <li>
            <Link
              to="/mading"
              className="nav-link px-2 link-dark"
              title="Kelas Brilliant"
            >
              Mading
            </Link>
          </li>
          <li>
            <Link
              to="/galeri"
              className="nav-link px-2 link-dark"
              title="Galeri Brilliant"
            >
              Galeri
            </Link>
          </li>
          <li>
            <Link
              to="/kontak"
              className="nav-link px-2 link-dark"
              title="Kontak Brilliant"
            >
              Kontak
            </Link>
          </li>
          {/* <li>
            <Link
              to="/kelas"
              className="nav-link px-2 link-dark"
              title="Kelas Brilliant"
            >
              Kelas
            </Link>
          </li>
          <li>
            <Link
              to="/kelasku"
              className="nav-link px-2 link-dark"
              title="Kontak Brilliant"
            >
              KelasKu
            </Link>
          </li> */}
        </ul>

        <div className="col-md-3 text-end">
          {/* <button type="button" className="btn btn-outline-primary me-2">
            Masuk
          </button>
          <button type="button" className="btn btn-primary">
            Daftar
          </button> */}
        </div>
      </header>
    </div>
  );
}
